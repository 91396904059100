.stepper-bg {
    background-color: #f5f5f5;
    background-image: url('../../assets/full_kid.jpeg');
    background-size: cover;
    background-position: center;
}

.light-overlay{
    background: linear-gradient(180deg, rgba(217, 217, 217, 0) 52.6%, #D9D9D9 100%);
}

.super-z {
    z-index: 99999;
}

.react-html5-camera-photo {
    height: 100%;
    align-items: center;
    display: flex;
    align-content: center;

    & video {
        width: unset !important;
        height: 100% !important;
        max-width: unset !important;
        // margin-left: -50%;
        margin-bottom: 0;
    }
}

.fixed-area {
    height: 480px;
}

.tag-bg {
    background-image: url('../../assets/avene_tag_bg.jpeg');
    background-size: cover;
    background-position: center;
}
