.bg-win {
    background-image: url('../../assets/win.jpeg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.bg-lose {
    background-image: url('../../assets/lose.jpeg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}