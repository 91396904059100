.reverted-form {
    // background: rgb(255,255,255);
    // background: -moz-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.38604651162790693) 100%);
    // background: -webkit-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.38604651162790693) 100%);
    // background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.38604651162790693) 100%);
    // filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
    background: linear-gradient(180deg, rgba(217, 217, 217, 0) 52.6%, #D9D9D9 100%)
}

.overlay-register {
    background: linear-gradient(180deg, rgba(217, 217, 217, 0) 52.6%, #D9D9D9 100%)
}

.main-section-register {
    background-image: url("../../assets/kid.jpeg");
    background-size: cover;
    background-position-y: top;
    background-position-x: left;
    background-repeat: no-repeat;
    background-attachment: fixed;
}