.overlay {
    // background: rgb(255,255,255);
    // background: -moz-linear-gradient(180deg, rgba(255,255,255,0) 27%, rgba(255,134,0,1) 70%, rgba(255,134,0,1) 100%);
    // background: -webkit-linear-gradient(180deg, rgba(255,255,255,0) 27%, rgba(255,134,0,1) 70%, rgba(255,134,0,1) 100%);
    // background: linear-gradient(180deg, rgba(255,255,255,0) 27%, rgba(255,134,0,1) 70%, rgba(255,134,0,1) 100%);
    // filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ff8600",GradientType=1); 
    background: linear-gradient(180deg, rgba(217, 217, 217, 0) 52.6%, #D9D9D9 100%);
}

.reverted {
    // background: rgb(255,255,255);
    // background: -moz-linear-gradient(180deg, rgba(255,255,255,0.713953488372093) 50%, rgba(255,134,0,0) 100%);
    // background: -webkit-linear-gradient(180deg, rgba(255,255,255,0.713953488372093) 50%, rgba(255,134,0,0) 100%);
    // background: linear-gradient(180deg, rgba(255,255,255,0.713953488372093) 50%, rgba(255,134,0,0) 100%);
    // filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ff8600",GradientType=1);  
}

.react-modal-sheet-container {
    /* custom styles */
   border-top-right-radius: 25px !important; 
   border-top-left-radius: 25px !important;
   overflow: hidden;
   background-color: #ff8600 !important;
}